import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Cart from './cart';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { VscFeedback } from "react-icons/vsc";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { LuGrip, LuLayoutGrid, LuMenu, LuStretchHorizontal } from "react-icons/lu";
import { TiThListOutline, TiThSmallOutline } from "react-icons/ti";
import Lang from '../general/Lang';
import Imgloading from './list/itemloading';

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        items: [],
        slide: [],
        loadSlide: false,
        loadCat: false,
        load: false,
        listview: "large",
        isCategoryVisible: false,
        lastScrollTop: 0,
        cart: false,
    }

    async componentDidMount(){

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.net/api/categories/ristretto");
        this.setState({ categories: data }, ()=>{
            this.setState({loadCat: true});
            this.getCategoryName(-1);
        });

        {
            // Get Slideshows
            const {data} = await axios.get("https://manage.caviarmenu.net/api/slideshow/ristretto");
            this.setState({ slide: data }, ()=>{
                this.setState({loadSlide: true});
            });
        }

        {
            const {data} = await axios.get("https://manage.caviarmenu.net/api/items/ristretto");
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
            });
        }

        this.layoutDirection();

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        if(id != -1)
            this.state.categories.map(cat => {
                if(cat.id == id) this.setState({ selectCategory: cat });
            });
        else
            this.setState({ selectCategory: this.state.categories[0] });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    handleCategory = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true});
    }

    handleListview = style => {
        this.setState({listview: style});
    }

    handleCartClose = close => {
        this.setState({cart: close});
    }

    addItemToCart = (item) => {
        this.props.addCartItem(item)
    }

    showMoreItems = (catId) => {
        this.getCategoryName(catId);
        this.setState({isCategoryVisible: true})
    }

    render() {

        const container = window !== undefined ? () => window.document.body : undefined;
        const drawerBleeding = 0;

        return (
            <div className='menu-items' onScroll={this.handleScroll}>
                <div className="head">
                    <div className='send-feedback'>
                        <Link to={"/feedback"}>
                            <VscFeedback /> <span>Feedback</span> 
                        </Link>
                    </div>

                    <div className="language">
                        <Lang handleLangaugeSubmit = {this.props.handleLangaugeSubmit} ></Lang>
                    </div>
                        
                    <img src={this.settings.menuLogo} alt="" />
                </div>

                <div className="social">

                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-square-facebook"></i></a>
                        }
                        
                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }
                        
                </div>


                <div className='items' id='itemContainer' style={ {'direction': this.props.Language != "en"? "rtl":"ltr"} }>

                <PhotoProvider maskOpacity={0.7}>
                    {
                        this.state.load ?
                        <> 
                            { 
                                this.state.categories.map(cat => <div key={cat.id}>
                                    <div className='catHead'>
                                        <div>
                                            {
                                                this.props.Language == "en" ? cat.name_eng
                                                : this.props.Language == "krd" ? cat.name_krd
                                                : cat.name_ar
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                    <div className='catItems'> 
                                        { 
                                            this.state.items[cat.id].slice(0, 2).map( (item, index) =>

                                                <div className="item" key={item.id}  >
                                                    <Imgloading Img={item.image} />
                                                    <div className='info-cart-add'>
                                                        <i className="fa-solid fa-plus"  onClick={()=> this.addItemToCart(item)}></i>
                                                    </div>
                                                    <div className='item-info'>
                                                        <h5 style={this.props.Language == "en"? {letterSpacing:'1px'}: {}}>
                                                            {
                                                                this.props.Language == "en" ? item.name_eng
                                                                : this.props.Language == "krd" ? item.name_krd
                                                                : item.name_ar
                                                            }          
                                                        </h5>
                                                        <div className='price'>
                                                            {item.price}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) 
                                        }
                                        <button className='catItemsMoreBtn' onClick={ () => this.showMoreItems(cat.id)} >
                                                {
                                                    this.props.Language == "en" ? 'More'
                                                    : this.props.Language == "krd" ? 'زێدەتر'
                                                    : 'مزید'
                                                }  
                                        </button>
                                    </div>
                                </div>
                                ) }
                        </>:
                        <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#313854">
                            <Skeleton height={50} />
                        </SkeletonTheme>
                    }

                </PhotoProvider>
                        
                </div>

                <PhotoProvider maskOpacity={0.7}>

                <Cart Cart={this.props.Cart}
                        SumPrice={this.props.SumPrice}
                        SumQty={this.props.SumQty}
                        Language = {this.props.Language} 
                        Show = {this.state.cart}
                        clearCartItem = {this.props.clearCartItem}
                        updateCartItem = {this.props.updateCartItem}
                        HandleCartClose = {this.handleCartClose} />
                
                </PhotoProvider>


                <PhotoProvider maskOpacity={0.7}>
                <SwipeableDrawer
                    container={container}
                    anchor="bottom"
                    open={this.state.isCategoryVisible}
                    onClose={ () => this.setState({isCategoryVisible: false})}
                    onOpen={ () => this.setState({isCategoryVisible: true}) }
                    swipeAreaWidth={drawerBleeding}
                    minFlingVelocity="450"
                    disableSwipeToOpen={false}
                    ModalProps={{
                    keepMounted: true,
                    }}
                >

                    <div className='DrawerHead'>
                        <div className='wipeableDrawerLine'></div>

                        <h2 className='SwipeableDrawerHead'>
                            {
                                this.props.Language == "en" ? this.state.selectCategory.name_eng
                                : this.props.Language == "krd" ? this.state.selectCategory.name_krd
                                : this.state.selectCategory.name_ar
                            }
                        </h2>
                    </div>
                    
                    <div className='SwipeableDrawerItems'>
                        {
                            this.state.isCategoryVisible ?
                            <>
                                { 
                                    this.state.items[this.state.selectCategory.id].map( (item, index) =>

                                        <div className="item" key={item.id}  >
                                            <Imgloading Img={item.image} />
                                            <div className='info-cart-add'>
                                                <i className="fa-solid fa-plus"  onClick={()=> this.addItemToCart(item)}></i>
                                            </div>
                                            <div className='item-info'>
                                                <h5 style={this.props.Language == "en"? {letterSpacing:'1px'}: {}}>
                                                    {
                                                        this.props.Language == "en" ? item.name_eng
                                                        : this.props.Language == "krd" ? item.name_krd
                                                        : item.name_ar
                                                    }          
                                                </h5>
                                                <div className='price'>
                                                    {item.price}
                                                </div>
                                            </div>
                                        </div>
                                    ) 
                                }

                            </>
                            : 
                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#313854">
                                <Skeleton height={50} />
                            </SkeletonTheme>
                        }
                    </div>

                </SwipeableDrawer>

                </PhotoProvider>


                <button className='cartButton'  onClick={()=> this.handleCartClose(true)}>
                    <i className="fa-solid fa-cart-shopping"></i>
                    {
                        this.props.SumQty? 
                        <span className='cartQty'> { this.props.SumQty } </span> 
                        : ""
                    }
                </button>


            </div>
        );
    }
}

export default Menu;